<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <date-range-input :value="customRange" @input="loadHistory" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <v-spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <apex-chart
                v-else
                height="440"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { formatHelper, httpHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

export default {
    name: 'RunningTimeChartView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: ([start, end]) =>
                        formatHelper.hoursAndMinutesDuration(
                            moment.duration(end - start).asSeconds()
                        ),
                },
                tooltip: {
                    x: {
                        format: 'dd.MM.yyyy HH:mm:ss',
                    },
                    y: {
                        formatter: (_, { dataPointIndex, seriesIndex }) => {
                            const [start, end] = this.series[seriesIndex].data[
                                dataPointIndex
                            ].y
                            return `${formatHelper.hoursAndMinutesDuration(
                                moment.duration(end - start).asSeconds()
                            )}:`
                        },
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    labels: {
                        formatter: value => {
                            const duration = this.series.reduce(
                                (total, asset) => {
                                    const current = asset.data
                                        .filter(({ x }) => x === value)
                                        .reduce((time, { y: [start, end] }) => {
                                            return time + end - start
                                        }, 0)

                                    return total + current
                                },
                                0
                            )

                            return duration
                                ? [
                                      value,
                                      `(${formatHelper.hoursAndMinutesDuration(
                                          duration / 1000
                                      )})`,
                                  ]
                                : value
                        },
                    },
                },
                legend: {
                    show: false,
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        tracker() {
            return this.trackers.find(tracker => tracker.id === +this.id)
        },
    },
    watch: {
        series() {
            this.chartOptions.yaxis.labels.show = !!this.series[0]?.data.length
        },
    },
    created() {
        moment.locale(this.$i18n.locale)
    },
    methods: {
        async loadHistory({ startDate, endDate }) {
            this.isLoading = true
            this.startDate = moment(startDate)
            this.endDate = moment(endDate)
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            await this.loadData()
            this.isLoading = false
        },
        async loadData() {
            const results = []
            const timestampMin = encodeURIComponent(this.startDate.format())
            const timestampMax = encodeURIComponent(this.endDate.format())

            let url =
                'running-time-history/?' +
                `asset=${this.tracker?.asset}` +
                `&start=${timestampMin}` +
                `&end=${timestampMax}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            this.series = [
                {
                    data: results.map(item => ({
                        x: this.$t('runningTime'),
                        y: [
                            new Date(item.start).getTime(),
                            new Date(item.end).getTime(),
                        ],
                    })),
                },
            ]
        },
    },
}
</script>

<i18n>
{
    "en": {
        "nHours": "{n} hour | {n} hours",
        "runningTime": "Running Time"
    },
    "de": {
        "nHours": "{n} Stunde | {n} Stunden",
        "runningTime": "Betriebsstunden"
    },
    "it": {
        "nHours": "{n} ora | {n} ore",
        "runningTime": "Tempo di Esecuzione"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

@include respond-to('for-tablet-down') {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .chart {
        border: none;
    }
}
</style>
